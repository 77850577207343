(function (global){
﻿'use strict';

var jquery = require('jquery');
global.$ = global.jQuery = jquery;      // To get the jQuery object in the global scope because Bootstrap 4 doesn't do modules properly

module.exports = (function () {

    // private methods


    // public methods

    /**
    * @ngdoc method
    * @name initializeNavigation
    * @description Setup the navigation for the site.  Event handlers, etc
    **/
    var initializeNavigation = function () {

        console.log("topNavigation initialized");

        
        var menu = new Mmenu("#pc-mobile-menu", {
            navbar: {
                add: true,
                title: "Menu <a href='javascript:;' class='menu-close' aria-label='close menu'><span class='fas fa-times'></span></a>"
            },
            "extensions": [
                "position-right"
            ],
            keyboardNavigation: {
                enable: true,
                enhance: true
            },
            screenReader: {
                aria: true
            },
            hooks: {
                "open:finish": function (panel) {
                    setTimeout(function () {
                        $hamburger.addClass("is-active");
                    }, 100);

                    //$close.focus();
                },
                "close:finish": function (panel) {
                    setTimeout(function () {
                        $hamburger.removeClass("is-active");
                    }, 100);
                }
            }
        },
        {
            classNames: {
                selected: "active"
            },
            offCanvas: {
                page: {
                    selector: ".wrapper"
                }
            },
            screenReader: {
                text: {
                    closeMenu: "Close menu",
                    closeSubmenu: "Close submenu",
                    openSubmenu: "Open submenu",
                    toggleSubmenu: "Toggle submenu"
                }
            }
        });

        var API = menu.API;

        var $hamburger = $(".hamburger");
        $hamburger.on("click", function () {
            API.open();
        });

        var $page = $(".mm-page");
        $page.on("click", function () {
            API.close();
        });

        var $close = $(".menu-close");
        $close.on("click", function () {
            API.close();
        });

        // ADD ARIA LABELS TO MMENU ITEMS
        var $mobileMenuPanelTitles = $('.mm-navbar__title');
        $mobileMenuPanelTitles.each(function () {
            var $panelTitle = $(this);
            $panelTitle.attr("aria-label", "Go up one level in the menu to the " + $panelTitle.text() + " section");
        });

        var $mobileMenuCloseSubmenuLink = $('.mm-btn_prev.mm-navbar__btn');
        var $mobileMenuCloseSubmenuTitles = $('.mm-btn_prev.mm-navbar__btn .mm-sronly');
        $mobileMenuCloseSubmenuLink.each(function () {
            var $closePanelTitle = $(this.firstElementChild);
            //IN PROGRESS WORKING ON GETTING UNIQUE STRINGS FOR EACH CLOSE ARROW
            //var $panelTitle = $(this.getAttribute(URL));
            $closePanelTitle.text("Close this submenu and return to the previous section");
        });
    };

    // Return an object exposing the public items
    return {
        initialize: initializeNavigation
    };

}());


}).call(this,typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})