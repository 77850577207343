(function (global){
﻿'use strict';

var jquery = require('jquery');
global.$ = global.jQuery = jquery;      // To get the jQuery object in the global scope because Bootstrap 4 doesn't do modules properly
var bootstrap = require('bootstrap');

module.exports = (function () {

    // private methods
    

    // public methods

    /**
    * @ngdoc method
    * @name initializeCarousels
    * @description Setup a carousel.  Event handlers, etc
    **/
    var initializeCarousels = function () {

        var $carousels = $('.pc-carousel:not(.single-slide)');
        var intervalTime = parseInt($carousels.attr("data-interval"));
        if (intervalTime === 0) {
            intervalTime = 6000; // default
        }
        $carousels.carousel({
            interval: intervalTime,
            pause: false,
            ride: true
        });

        var $carouselControls = $carousels.children(".carousel-control-position");

        // This each is to allow any number of carousels to be added to a page
        $carousels.each(function () {
            var $carousel = $(this);

            var $playButton = $carousel.find('.play-button');
            var $pauseButton = $carousel.find('.pause-button');
            var $videoItem = $carousel.find('.video-item');

            $videoItem.on('click', function (e) {
                var $this = $(this);
                $carousel.carousel('pause');
                $carousel.addClass("is-paused");
                var $carouselItem = $this.closest('.carousel-item');
                $carouselItem.addClass("playing");
                $carouselControls.addClass("video");
            });
            $pauseButton.on('click', function (e) {
                $carousel.carousel('pause');
                $carousel.addClass("is-paused");

                e.preventDefault();
                return false;
            });
            $playButton.on('click', function (e) {
                $carousel.carousel('cycle');
                $carousel.removeClass("is-paused");

                e.preventDefault();
                return false;
            });

            $carousel.on('slid.bs.carousel', function (e) {
                var $carouselItem = $(e.relatedTarget);
                if ($carouselItem.hasClass("video")) {
                    if ($carouselItem.hasClass("playing")) {
                        $carouselControls.addClass("video");
                        $carouselControls.addClass("video-playing");
                    } else {
                        $carouselControls.removeClass("video-playing");
                    }
                } else {
                    $carouselControls.removeClass("video");
                    $carouselControls.removeClass("video-playing");
                }
            });
        });

        console.log("carousels initialized");
    };

    // Return an object exposing the public items
    return {
        initialize: initializeCarousels
    };

}());


}).call(this,typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})