(function (global){
﻿'use strict';

var jquery = require('jquery');
global.$ = global.jQuery = jquery;      // To get the jQuery object in the global scope because Bootstrap 4 doesn't do modules properly
//var VimeoPlayer = require("@vimeo/player");
//var models = require("../models/videomodels");


module.exports = (function () {

    // private methods
    var models = null;

    /**
    * @ngdoc method
    * @name prepareYouTubeVideo
    * @description Setup youtube video
    **/
    var prepareYouTubeVideo = function ($this) {

        var youtubeVideos = {};

        var player = new YT.Player($this.attr("data-video-container-id"), {
            // Don't need this because we are 
            height: '390',
            width: '640',
            videoId: $this.attr("data-video-id"),
            playerVars: {rel: 0},
            events: {
                'onReady': function () { console.log("youtube video ready"); },
                'onStateChange': function (s) {
                    if (s.data === YT.PlayerState.PAUSED) {
                        videoPaused($this);
                    }
                    else if (s.data === YT.PlayerState.ENDED) {
                        videoEnded($this);
                    }
                    else if (s.data === YT.PlayerState.PLAYING) {
                        videoPlaying($this);
                    }
                }
            }
        });

        var videoPlayerSettings = new models.VideoPlayer();
        videoPlayerSettings.player = player;
        videoPlayerSettings.videoId = $this.attr("data-video-id");
        videoPlayerSettings.imageOverlay = $this.parent();
        videoPlayerSettings.imageClickFunction = videoPlayerSettings.imageOverlay.click(function (e) {
            var $thisVideoItem = $(this);
            $thisVideoItem.addClass("active");
            var vid = $thisVideoItem.children(".video-container").attr("data-video-id");
            var videoObj = youtubeVideos[vid];
            videoObj.player.playVideo();


            e.preventDefault();
            return false;
        });

        youtubeVideos[videoPlayerSettings.videoId] = videoPlayerSettings;

    };


    /**
    * @ngdoc method
    * @name prepareVimeoVideo
    * @description Setup vimeo video
    **/
    var prepareVimeoVideo = function ($this) {

        var vimeoVideos = {};

        var player = new Vimeo.Player($this.attr("data-video-container-id"));
        player.ready().then(function () {
            console.log("vimeo video ready");
        });
        player.on("play", function () {
            console.log("vimeo video playing");
            videoPlaying($this);
        });
        player.on("pause", function () {
            console.log("vimeo video paused");
            videoPaused($this);
        });
        player.on("ended", function () {
            console.log("vimeo video done playing");
            videoEnded($this);
        });

        var videoPlayerSettings = new models.VideoPlayer();
        videoPlayerSettings.player = player;
        videoPlayerSettings.videoId = $this.attr("data-video-container-id");
        videoPlayerSettings.imageOverlay = $this.parent();
        videoPlayerSettings.imageClickFunction = videoPlayerSettings.imageOverlay.click(function (e) {
            var $this = $(this);
            $this.addClass("active");
            var vid = $this.children(".video-container").attr("data-video-container-id");
            var videoObj = vimeoVideos[vid];
            videoObj.player.play();

            e.preventDefault();
            return false;
        });

        vimeoVideos[videoPlayerSettings.videoId] = videoPlayerSettings;

    };


    /**
    * @ngdoc method
    * @name videoPlaying
    * @description Update the styles, carousel, and restart the carousel on end of the video playing
    **/
    function videoPlaying($this) {
        var $carouselControls = $this.parents(".pc-carousel").children(".carousel-control-position");
        var $carouselArrows = $this.parents(".pc-carousel").children(".carousel-arrows-position");
        $carouselControls.addClass("video-playing");
        $carouselArrows.addClass("video-playing");
    }

    /**
    * @ngdoc method
    * @name videoPaused
    * @description Update the styles, carousel, and restart the carousel on end of the video playing
    **/
    function videoPaused($this) {
        //var $carouselControls = $this.parents(".pc-carousel").children(".carousel-control-position");
        var $carouselArrows = $this.parents(".pc-carousel").children(".carousel-arrows-position");
        $carouselArrows.removeClass("video-playing");
    }

    /**
    * @ngdoc method
    * @name videoEnded
    * @description Update the styles, carousel, and restart the carousel on end of the video playing
    **/
    function videoEnded($this) {
        var $carouselControls = $this.parents(".pc-carousel").children(".carousel-control-position");
        var $carouselArrows = $this.parents(".pc-carousel").children(".carousel-arrows-position");
        $carouselControls.removeClass("video-playing");
        $carouselControls.removeClass("video");
        $carouselArrows.removeClass("video-playing");
        var $carouselItem = $this.closest('.carousel-item');
        var $videoItem = $this.closest('.video-item');
        $carouselItem.removeClass("playing");
        $videoItem.removeClass("active");
        var $carousel = $this.closest('.carousel');
        $carousel.carousel('cycle');
        $carousel.removeClass("is-paused");
    }


    /**
    * @ngdoc method
    * @name setupAllYouTubePlayers
    * @description Get all youtube videos and call the method to load the player
    **/
    function setupAllYouTubePlayers() {
        var $video = $('.video-container');

        $video.each(function (index) {
            var $this = $(this);
            if ($this.is(".youtube")) {

                prepareYouTubeVideo($this);
            }
        });

    }



    /**
    * @ngdoc method
    * @name loadYouTubeApi
    * @description Setup youtube iframe api
    **/
    function loadYouTubeApi() {
        if (typeof (YT) == 'undefined' || typeof (YT.Player) == 'undefined') {

            var tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            window.onYouTubePlayerAPIReady = function () {
                setupAllYouTubePlayers();
            };

        } else {
            setupAllYouTubePlayers();
        }
    }



    // public methods

    /**
    * @ngdoc method
    * @name initializeVideo
    * @description Setup a carousel.  Event handlers, etc
    **/
    var initializeVideo = function (videomodels) {

        models = videomodels;

        loadYouTubeApi();

        // Setup Vimeo videos because they don't need to wait for the API to load
        var videos = $('.video-container');

        videos.each(function () {
            var self = $(this);
            if (self.is(".vimeo"))
            {
                prepareVimeoVideo(self);
            }
            
        });

        console.log("video initialized");
    };

    // Return an object exposing the public items
    return {
        initialize: initializeVideo
    };
    document.addEventListener('DOMContentLoaded', function () {
        var carouselImages = document.querySelectorAll('.carousel-image');
        carouselImages.forEach(function (image) {
            image.addEventListener('click', function () {
                var parentItem = image.closest('.carousel-item');
                var videoItem = parentItem.querySelector('.video-item');
                image.style.display = 'none'; // Hide the image
                videoContainer.style.display = 'block';
            });
        });
    });

}());


}).call(this,typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})